import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-rangeslider/lib/index.css'
import 'antd/dist/antd.css'
import './assets/css/custom.css'
import { HeliosWrap } from '@oneblinktech-org/helios-base'
import { menus } from './fakeData/sideBar';

// Pages
import Dashboard from "./pages/Dashboard"
import EditProfile from './pages/EditProfile'
import GroupFinder from './pages/GroupFinder'
import GroupHome from './pages/GroupHome'
import LeftSideComponent from './components/leftSideComponent'
import Navbar from './components/Navbar'
import { NotificationContainer } from 'react-notifications';
import PastReferrals from './pages/PastReferrals'
import Profile from './pages/Profile'
import SendReferral from './pages/SendReferral'
import Subscriptions from './components/IframeComponents/subscriptions'
import Applications from './components/IframeComponents/applications'
import Goals from './components/IframeComponents/goals'
import Gratitudes from './components/IframeComponents/gratitudes'
import MyEvents from './components/IframeComponents/myEvents'
import EventRegister from './components/IframeComponents/eventRegister';
import JoinGroup from './components/IframeComponents/joinGroup';
import ChairEvents from './components/IframeComponents/chairEvents';
import ChairRegisteredGuests from './components/IframeComponents/chairRegisteredGuests';
import InviteGuest from './components/IframeComponents/inviteGuest'

function App(props) {

    const siteText = /\.highachievers\.com$/.test(window.location.hostname) ? 'High Achievers' : 'Trustegrity';

    return (
    <div className="App">
      <Switch>
        <Route path={["/group/:id"]}>
          <HeliosWrap
            captchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            fullWidth={false}
            orgChangeEvent={(orgId) => { }}
            siteText={siteText}
            apiUrl={process.env.REACT_APP_API_URL || ""}
            searchComponent={<></>}
            leftSideComponent={<LeftSideComponent />}
            isWelcome={true}
            isNavShow={process.env.REACT_APP_SHOW_NAV_SIDE || true}
            navbar={<Navbar />}
          >
            <Switch>
              <Route path="/group/:id" component={GroupHome} />
            </Switch>
          </HeliosWrap>
        </Route>

        <Route path={["/profile", "/editProfile", "/sendReferral", "/pastReferral"]}>
          <HeliosWrap
            captchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            fullWidth={true}
            orgChangeEvent={(orgId) => { }}
            siteText={siteText}
            apiUrl={process.env.REACT_APP_API_URL || ""}
            searchComponent={<></>}
            leftSideComponent={<LeftSideComponent />}
            isWelcome={false}
            isNavShow={process.env.REACT_APP_SHOW_NAV_SIDE || true}
            navbar={<Navbar />}
          >
            <Switch>
              <Route path="/profile" component={Profile} />
              <Route path="/editProfile" component={EditProfile} />
              <Route path="/sendReferral" component={SendReferral} />
              <Route path="/pastReferral" component={PastReferrals} />
            </Switch>
          </HeliosWrap>
        </Route>

        <Route path={[
          '/subscriptions',
          '/applications',
          '/goals',
          '/gratitudes',
          '/my-events',
          '/event-register',
          '/join-group',
          '/chair-events',
          '/chair-registered-guests',
          '/invite-guest/:legacyGroupId',
        ]}>
          <HeliosWrap
              captchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              fullWidth={false}
              orgChangeEvent={(orgId) => { }}
              siteText={siteText}
              apiUrl={process.env.REACT_APP_API_URL || ""}
              searchComponent={<></>}
              leftSideComponent={<LeftSideComponent />}
              isWelcome={false}
              isNavShow={process.env.REACT_APP_SHOW_NAV_SIDE || true}
              navbar={<Navbar />}
          >
            <Switch>
              <Route path="/subscriptions" component={Subscriptions} />
              <Route path="/applications" component={Applications} />
              <Route path="/goals" component={Goals} />
              <Route path="/gratitudes" component={Gratitudes} />
              <Route path="/my-events" component={MyEvents} />
              <Route path="/event-register" component={EventRegister} />
              <Route path="/join-group" component={JoinGroup} />
              <Route path="/chair-events" component={ChairEvents} />
              <Route path="/chair-registered-guests" component={ChairRegisteredGuests} />
              <Route path="/invite-guest/:legacyGroupId" component={InviteGuest} />
            </Switch>
          </HeliosWrap>
        </Route>

        <Route path={["/"]}>
          <HeliosWrap
            captchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            fullWidth={true}
            orgChangeEvent={(orgId) => { }}
            siteText={siteText}
            apiUrl={process.env.REACT_APP_API_URL || ""}
            searchComponent={<></>}
            leftSideComponent={<LeftSideComponent />}
            isWelcome={true}
            isNavShow={process.env.REACT_APP_SHOW_NAV_SIDE || true}
            navbar={<Navbar />}
          >
            <Switch>
              <Route path="/" component={GroupFinder} />
              <Redirect to="/login" />
            </Switch>
          </HeliosWrap>
        </Route>

      </Switch>
      <NotificationContainer />
    </div>
  );
}

export default App;