import React, { useEffect, useRef, useState } from 'react'
import { Divider, Popover, Slider } from 'antd'
import { defaultLocation } from '../../helpers/globals.js'
import { createApiClient } from '@oneblinktech-org/helios-base'
import { NotificationManager } from 'react-notifications'

const apiUrl = process.env.REACT_APP_API_URL
const { get, post } = createApiClient({ apiUrl })

const formatter = (value) => `${value} miles`
const DIVIDER_COLOR = '#C4C4C4'
const SELECT_COLOR = '#39424E'

const DistanceContentComponent = ({
    closePopover,
    changeDistance,
    distance,
}) => {
    return (
        <div className="distance_content">
            <Slider tooltip={{
                formatter,
            }} max="1000" min={5} onChange={changeDistance} defaultValue={50}
                    value={distance}/>
            <button onClick={closePopover} type="button">Ok</button>
        </div>
    )
}

function SearchForm ({
    setCurrentLocation,
    setIsLoading,
    bounds,
    showSearch,
    onHideMap,
    isSmall,
    setIsSearchBarSmall,
    zoom,
    setMapCenterAndZoom,
    currentLocation,
    isReleaseMouseEvent,
    movementCounter
}) {
    const [query, setQuery] = useState('')
    const [prevQuery, setPrevQuery] = useState('')
    const [typeList, setTypeList] = useState([])
    const [type, setType] = useState('in-person')
    const [distance, setDistance] = useState(50)
    const [open, setOpen] = useState(false)
    const prevBoundsRef = useRef()

    useEffect(async () => {
        // try {
        //     if (!bounds) {
        //         console.log('001 - /ithaca/groups/')
        //         const result = await get('/ithaca/groups')
        //         if (result.count > 0) {
        //             let uniqueTypes = [
        //                 ...new Set(result.groups.map(group => group.type))]
        //             let formattedTypes = uniqueTypes.map(type => ({
        //                 value: type,
        //                 label: type.split('-').
        //                     map(word => word.charAt(0).toUpperCase() +
        //                         word.slice(1)).
        //                     join(' '),
        //             }))
        //             setTypeList(formattedTypes)
        //         }
        //
        //         let location = JSON.parse(
        //             localStorage.getItem('viewerLocation'))
        //         if (location) {
        //             onSearch(location.lat, location.lng)
        //         } else {
        //             onSearch(defaultLocation.lat, defaultLocation.lng)
        //         }
        //     }
        // } catch (e) {
        //     throw e
        // }
    }, [])

    useEffect(() => {
        type == 'virtual' ? onHideMap(false) : onHideMap(true)
    }, [type])

    useEffect(() => {
        if (bounds && (!prevBoundsRef.current ||
            !areBoundsEqual(bounds, prevBoundsRef.current))) {
            onSearch(currentLocation?.lat, currentLocation?.lng, false)
            prevBoundsRef.current = bounds
        }
    }, [bounds])

    const areBoundsEqual = (bounds1, bounds2) => {
        return JSON.stringify(bounds1) === JSON.stringify(bounds2)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        }

        getLatLongFromPostalCode(query).then((coordinates) => {
            setCurrentLocation(coordinates)
        }).catch((error) => {
            console.error('Error fetching coordinates:', error)
        })

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(success, error, options)
        } else {
            onSearch(defaultLocation.lat, defaultLocation.lng, false, false)
        }

        function success (position) {
            onSearch(position.coords.latitude, position.coords.longitude, true, true)
        }

        function error (err) {
            onSearch(defaultLocation.lat, defaultLocation.lng, false, false)
        }
    }

    const onSearch = async (latitude, longitude, formSearch=false, isSearch = false) => {
        try {
            if ((isReleaseMouseEvent || isSearch) && movementCounter >= 0.5) {
                setIsLoading(true)
                let url = '/ithaca/groups?'
                if (query != '' && formSearch) {
                    url += 'search=' + query + '&'
                }

                if (type != '') {
                    url += 'type=' + type + '&'
                }

                if (bounds != null) {
                    url += 'bounds[swLat]=' + bounds.swLat + '&bounds[swLng]=' +
                        bounds.swLng + '&bounds[neLat]=' + bounds.neLat +
                        '&bounds[neLng]=' + bounds.neLng + '&'
                }

                url += 'coordinates=' + `${latitude},${longitude}&`
                url += 'distance=' + distance
                url += `&referrer=${window.location.hostname}`

                let location = await JSON.parse(
                    localStorage.getItem('userLocation'))
                if (location)
                    url += `&userLatitude=${location.lat}&userLongitude=${location.lng}`

                const result = await get(url)
                if (result) {
                    result.groups.sort((a, b) => a.distance - b.distance)
                    showSearch(result, distance, formSearch)
                    setIsLoading(false)
                    setPrevQuery(query)
                } else {
                    setIsLoading(false)
                }
            }
        } catch (e) {
            throw e
        }
    }

    const isValidPostalCode = (code) => {
        return /^\d{5}$/.test(code)
    }

    const getLatLongFromPostalCode = async (code) => {
        return new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder()
            geocoder.geocode({ address: code }, (results, status) => {
                if (status === 'OK' && results && results.length > 0) {
                    const location = results[0].geometry.location
                    resolve({ lat: location.lat(), lng: location.lng() })
                } else {
                    reject(new Error('Geocoding failed'))
                }
            })
        })
    }

    const handleChange = (value) => {
        // setType(value)
    }

    const hide = () => {
        if (distance >= 5 && distance <= 1000)
            setOpen(false)
        else
            NotificationManager.error('set distance between min 5 and max 1000',
                'Error', 7000)

    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }

    return (
        <form onSubmit={handleSubmit} className={`m-auto map_search-form`}>
            <div className="input-group">
                <svg onClick={() => setIsSearchBarSmall(false)} width="16"
                     height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M11.745 11.04c.934-1.111 1.514-2.524 1.514-4.09 0-3.457-2.826-6.283-6.31-6.283A6.285 6.285 0 0 0 .667 6.951c0 3.457 2.827 6.284 6.284 6.284a6.19 6.19 0 0 0 4.088-1.514l3.301 3.41a.499.499 0 1 0 .716-.695l-3.31-3.397ZM6.95 12.25c-2.902 0-5.275-2.372-5.275-5.3A5.272 5.272 0 0 1 6.95 1.677a5.288 5.288 0 0 1 5.274 5.275c0 2.902-2.347 5.3-5.274 5.3Z"
                          fill="#3F3B3B"/>
                </svg>
                <input className="ml-2" id="search_input" type="text"
                       value={query} onChange={(e) => setQuery(e.target.value)}
                       placeholder="Search for groups ..."/>
            </div>
            <>
                {/* <Select
                    bordered={false}
                    placeholder="Type"
                    style={{
                        width: 120,
                        fontSize: '0.75rem',
                        color: SELECT_COLOR,
                    }}
                    dropdownStyle={{
                        backgroundColor: 'white',
                    }}
                    defaultValue="In Person"
                    suffixIcon={<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 .923 9.09 0 5 4.154.91 0 0 .923 5 6l5-5.077Z" fill="#39424E"/></svg>}
                    dropdownMatchSelectWidth={false}
                    onChange={handleChange}
                    options={typeList}
                /> */} <Divider type="vertical" style={{
                backgroundColor: DIVIDER_COLOR,
                height: '20px',
            }}/>
                <div className="near_me">
                    <Popover placement="bottom" title={'Distance from me'}
                             content={() => <DistanceContentComponent
                                 closePopover={hide}
                                 changeDistance={(value) => setDistance(value)}
                                 distance={distance}/>} trigger="click"
                             open={open} onOpenChange={handleOpenChange}> <input
                        placeholder="miles" type="number" min={5} max={1000}
                        onChange={(e) => setDistance(e.target.value)}
                        value={distance} onClick={(e) => e.stopPropagation()}/>
                        <a>
                            <span className="mr-2">
                                miles
                            </span>
                            <svg width="10" height="6" viewBox="0 0 10 6"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10 .923 9.09 0 5 4.154.91 0 0 .923 5 6l5-5.077Z"
                                      fill="#39424E"/>
                            </svg>
                        </a> </Popover>
                </div>
                <button type="submit" className="mr-2 mr-md-0">
                    <img src={require(
                        '../../assets/images/search_white.png').default}
                         alt=""/> <span>Search</span>
                </button>
            </>
        </form>
    )
}

export default SearchForm